import { useCallback, useRef, useState } from "react";
import {
  Text,
  Group,
  Button,
  createStyles,
  TextInput,
  Tabs,
  Modal,
  LoadingOverlay,
} from "@mantine/core";
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import { formatEntityTitle } from "../utils";
import { useDispatch } from "react-redux";
import { addNewVideo } from "../features/videos/videosSlice";
import VFIconComponent from "./icon/vf-icon";
import { useErrorHandler } from "../hooks/useErrorHandler";
import { showNotification } from "@mantine/notifications";
import UploadedFileView from "./UploadedFileView";

const useStyles = createStyles(() => ({
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 auto",
  },
  root: {
    textAlign: "center",
    marginTop: 35,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inner: {
    ".mantine-Paper-root": {
      borderRadius: 10,
    },
  },
  tabsList: {
    display: "block",
    border: "none",
    button: {
      margin: 0,
      border: "none",
      display: "inline-block",
      padding: "7px 16px",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      backgroundColor: "#F5F5F5",
      "&[data-active]": {
        backgroundColor: "#29B1A1",
        color: "#FFF",
      },
    },
  },
  tabsPanel: {
    padding: "24px 18px",
    border: "1px solid #29B1A1",
    borderRadius: "16px",
    marginBottom: "25px",
  },
  youtubeUrlInputRoot: {
    position: "relative",
    marginBottom: "25px",
    input: {
      "&:focus": {
        borderColor: "#29B1A1",
      },
    },
  },

  youtubeUrlInputInvalid: {
    borderColor: "#fa5252",
  },
  youtubeUrlInputError: {
    position: "absolute",
    bottom: "-20px",
    left: 0,
  },
  dropzoneRoot: {
    height: 288,
    marginBottom: 20,
    border: "2px dashed #e9ecef",
    backgroundColor: "#f8f9fa",
    "&:hover": {
      backgroundColor: "#e2e6ea",
    },
  },
  removeButton: {
    color: "#fa5252",
    backgroundColor: "#FFFFFF",
    borderColor: "#fa5252",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#fa5252",
      borderColor: "#fa5252",
    },
  },
}));

interface AddNewVideoProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface YouTubeVideo {
  id: string;
  thumbnailUrl: string;
}

const YOUTUBE_URL_REGEX =
  /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

const extractYouTubeVideoId = (url: string): string | null => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const getYouTubeThumbnailUrl = (videoId: string): string => {
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

const validateYouTubeUrl = (url: string): YouTubeVideo | null => {
  if (!YOUTUBE_URL_REGEX.test(url)) {
    return null;
  }

  const videoId = extractYouTubeVideoId(url);
  if (!videoId) {
    return null;
  }

  return {
    id: videoId,
    thumbnailUrl: getYouTubeThumbnailUrl(videoId),
  };
};

export const AddNewVideoModal: React.FC<AddNewVideoProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { classes, theme } = useStyles(),
    openRef = useRef<() => void>(null),
    [youtubeUrl, setYoutubeUrl] = useState(""),
    [file, setFile] = useState<null | FileWithPath>(null),
    [activeTab, setActiveTab] = useState("youtube"),
    [videoTitle, setVideoTitle] = useState(""),
    dispatch = useDispatch(),
    [isLoading, setIsLoading] = useState(false);

  const [url, setUrl] = useState<string>("");
  const [video, setVideo] = useState<YouTubeVideo | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { handleError } = useErrorHandler();

  const uploadVideo = async () => {
    setIsLoading(true);
    let videoPayload = null;

    const formData = new FormData();
    const videoData = activeTab === "youtube" ? youtubeUrl : file;
    formData.append(activeTab === "youtube" ? "url" : "file", videoData);
    formData.append("source_type", activeTab);

    if (videoTitle !== "") {
      formData.append("title", videoTitle);
    }

    videoPayload = formData;

    try {
      await dispatch(addNewVideo(videoPayload)).unwrap();

      setIsOpen(false);

      showNotification({
        radius: "md",
        icon: <VFIconComponent type="success" backgroundColor="#FFFFFF" />,
        title: "Success",
        message: "Video successfully added!",
      });
    } catch (response: any) {
      handleError(response.error, response.statusCode);
    }

    setIsLoading(false);
  };

  const handleUrlChange = useCallback((e: React.ChangeEvent<any>) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setError(null);

    if (newUrl.trim() === "") {
      setVideo(null);
      return;
    }

    const validatedVideo = validateYouTubeUrl(newUrl);
    if (validatedVideo) {
      setVideo(validatedVideo);
    } else {
      setVideo(null);
      setError("Invalid URL. Please enter a valid YouTube video URL.");
    }
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    clearTabsContent();
    setActiveTab("youtube");
    setIsLoading(false);
  }, []);

  const clearTabsContent = useCallback(() => {
    setYoutubeUrl("");
    setFile(null);
    setVideoTitle("");
    setUrl("");
    setVideo(null);
    setError(null);
  }, []);

  return (
    <Modal
      withCloseButton={false}
      centered
      transition="fade"
      classNames={{
        root: classes.root,
        title: classes.title,
        inner: classes.inner,
      }}
      transitionDuration={650}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={580}
      padding={0}
      opened={isOpen}
      onClose={() => closeModal()}
    >
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ size: "lg", variant: "dots" }}
      />
      <div
        className="style-header"
        style={{
          height: "50px",
          width: "100%",
          backgroundColor: "#29b1a1",
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <VFIconComponent
          style={{ cursor: "pointer" }}
          type="close"
          onClick={() => setIsOpen(false)}
          size={30}
        />
      </div>
      <div className="content" style={{ padding: "20px 40px" }}>
        <div
          className="icon-holder"
          style={{
            textAlign: "center",
          }}
        >
          <VFIconComponent
            type={"upload-2"}
            size={70}
            style={{
              display: "block",
              margin: "0 auto 20px auto",
            }}
          />
        </div>
        <div className="modal-header">
          <h1
            className="title"
            style={{
              margin: "0 0 20px 0",
            }}
          >
            Add New Project
          </h1>
          <p
            className="subtitle"
            style={{
              color: "#666666",
              marginBottom: "30px",
            }}
          >
            Upload a source for your new project
          </p>
          <div className="tabs-holder">
            <Tabs
              value={activeTab}
              onTabChange={(value: string) => {
                setActiveTab(value);
                clearTabsContent();
              }}
              classNames={{
                tabsList: classes.tabsList,
                panel: classes.tabsPanel,
              }}
            >
              <Tabs.List>
                <Tabs.Tab value="youtube">YouTube</Tabs.Tab>
                <Tabs.Tab value="file">File</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="youtube">
                <div
                  className="youtube-source-wrapper"
                  style={{
                    textAlign: "left",
                  }}
                >
                  <TextInput
                    classNames={{
                      root: classes.youtubeUrlInputRoot,
                      error: classes.youtubeUrlInputError,
                      invalid: classes.youtubeUrlInputInvalid,
                    }}
                    error={error}
                    label="Enter YouTube Video URL"
                    placeholder="https://www.youtube.com/watch?v=..."
                    value={youtubeUrl}
                    onChange={(event) => {
                      setYoutubeUrl(event.currentTarget.value);
                      handleUrlChange(event);
                    }}
                  />
                  {/*  */}
                  <div
                    className="video-thumbnail-holder"
                    style={{
                      width: "100%",
                      height: "288px", // Calculated as (available_width / 16) * 9
                      backgroundColor: "#f8f9fa",
                      borderRadius: "8px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {video ? (
                      <img
                        src={video?.thumbnailUrl}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover" as const,
                          position: "absolute",
                          top: 0,
                        }}
                        alt="Video thumbnail"
                      />
                    ) : (
                      <div>
                        <VFIconComponent
                          type={"youtube-text"}
                          size={55}
                          style={{
                            display: "block",
                            margin: "0 auto 20px auto",
                          }}
                        />
                        <p
                          style={{
                            textAlign: "center" as const,
                            color: "#666",
                          }}
                        >
                          Video preview will appear here
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="file">
                {file === null ? (
                  <>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#212529",
                        marginBottom: "25px",
                      }}
                    >
                      Initiate your vision with a video file
                    </p>
                    <Dropzone
                      openRef={openRef}
                      // TO DO - add loading state, this component has a built in spinner.
                      onDrop={(files) => {
                        setFile(files[0]);
                      }}
                      classNames={{ root: classes.dropzoneRoot }}
                      accept={[MIME_TYPES.mp4]}
                      maxSize={5368709120} // 5 GB * 1,024 MB/GB * 1,024 KB/MB * 1,024 B/KB = 5,368,709,120 bytes
                      multiple={false}
                    >
                      <div style={{ pointerEvents: "all" }}>
                        <Text align="center" weight={700} size="lg" mt="xl">
                          <Dropzone.Accept>Drop files here</Dropzone.Accept>
                          <Dropzone.Reject>
                            Mp4 file less than 3 GB
                          </Dropzone.Reject>
                        </Text>
                        <VFIconComponent
                          type="upload"
                          size={50}
                          style={{ margin: "0 auto" }}
                        />
                        <Text align="center" size="sm" mt="xs" color="dimmed">
                          Drag a file <br />
                          or
                        </Text>
                      </div>
                      <Group position="center" mt="md">
                        <Button
                          className="vf-button-filled"
                          onClick={() => openRef.current?.()}
                        >
                          Select file
                        </Button>
                      </Group>
                    </Dropzone>
                  </>
                ) : (
                  <UploadedFileView
                    fileName={formatEntityTitle(file.name, 28)}
                    fileSize={file.size}
                    onDelete={() => setFile(null)}
                  />
                )}
              </Tabs.Panel>
            </Tabs>
          </div>
        </div>
        <div
          className="modal-actions"
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "30px",
          }}
        >
          <Button
            style={{
              minWidth: "120px",
            }}
            className="vf-button-filled"
            disabled={
              (activeTab === "youtube" && (error || !youtubeUrl)) ||
              (activeTab === "file" && file === null)
            }
            onClick={uploadVideo}
            size="md"
            variant="outline"
          >
            Upload
          </Button>
          <Button
            style={{
              minWidth: "120px",
            }}
            className="vf-button-outlined"
            onClick={() => closeModal()}
            size="md"
            variant="outline"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
