import { IVideoSegment } from "./types";
import { spring, interpolate } from "remotion";

export const getCurrentSegment = (
  currentTimeInSeconds: number,
  segments: IVideoSegment[]
): IVideoSegment | null => {
  const TOLERANCE = 0.04;

  return (
    segments.find(
      (seg) =>
        currentTimeInSeconds >= seg.start - TOLERANCE &&
        currentTimeInSeconds <= seg.end + TOLERANCE
    ) || null
  );
};

export type AnimationType = "fade" | "slideUp" | "scaleUp" | "typing" | "none";

export interface AnimationConfig {
  frame: number;
  fps: number;
  startFrame: number;
  endFrame: number;
}

export const getAnimationStyles = (
  type: AnimationType,
  config: AnimationConfig
) => {
  // Calculate progress based on exact frames
  const progress = spring({
    frame: config.frame - config.startFrame,
    fps: config.fps,
    config: {
      damping: 80,
      mass: 0.4,
      stiffness: 120,
      overshootClamping: true,
    },
  });

  // Ensure progress is clamped between 0 and 1
  const clampedProgress = Math.max(0, Math.min(1, progress));

  switch (type) {
    case "fade":
      return {
        opacity: interpolate(clampedProgress, [0, 0.7], [0, 1], {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
        }),
      };

    case "slideUp":
      return {
        opacity: interpolate(clampedProgress, [0, 0.7], [0, 1], {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
        }),
        transform: `translateY(${(1 - clampedProgress) * 15}px)`,
      };

    case "scaleUp":
      return {
        opacity: interpolate(clampedProgress, [0, 0.7], [0, 1], {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
        }),
        transform: `scale(${interpolate(clampedProgress, [0, 1], [0.95, 1], {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
        })})`,
      };

    case "typing":
      return {
        clipPath: `inset(0 ${interpolate(clampedProgress, [0, 0.8], [100, 0], {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
        })}% 0 0)`,
        opacity: 1,
      };

    case "none":
    default:
      return {};
  }
};
