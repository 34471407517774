import React, { useState } from "react";
import { useCurrentFrame, useVideoConfig } from "remotion";
import { WordComponent } from "./Word";
import { ISubtitle } from "../types";
import { useRemotionContext } from "../RemotionContext";
import { AnimationType, getAnimationStyles } from "./../helpers";

interface SegmentProps {
  segment: ISubtitle;
  animationType: AnimationType;
}

const InnerComponent: React.FC<SegmentProps> = ({ segment, animationType }) => {
  const { scale, videoRatio, subtitles } = useRemotionContext();
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const [wordRefs] = useState(() => {
    return new Array(segment.words.length)
      .fill(0)
      .map(() => React.createRef<HTMLSpanElement>());
  });

  // Animation for the entire subtitle segment
  const segmentAnimationStyles = getAnimationStyles(animationType, {
    frame,
    fps,
    startFrame: segment.start * fps,
    endFrame: segment.end * fps,
  });

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          fontSize: (subtitles.config.size * videoRatio) / scale,
          fontFamily: subtitles.config.font,
          ...(subtitles.config.stroke && {
            textShadow: `${(3 * videoRatio) / scale}px ${
              (3 * videoRatio) / scale
            }px ${(10 * videoRatio) / scale}px ${subtitles.config.strokeColor}`,
            WebkitTextStroke: `${(3 * videoRatio) / scale}px ${
              subtitles.config.strokeColor
            }`,
          }),
          ...segmentAnimationStyles,
        }}
      >
        {segment.words.map((word, i) => {
          return (
            <WordComponent
              ref={wordRefs[i]}
              key={i}
              index={i}
              word={word}
              animationType={animationType}
            />
          );
        })}
      </div>
    </div>
  );
};

export const SegmentComp: React.FC<SegmentProps> = ({
  segment,
  animationType,
}) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const timeInSeconds = frame / fps;
  const { start, end } = segment;

  if (timeInSeconds < start || timeInSeconds > end) {
    return null;
  }

  return <InnerComponent segment={segment} animationType={animationType} />;
};
