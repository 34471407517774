import { useRef, useEffect, useState, useCallback } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { ICropBox } from "../types";
import { Subtitles } from "./Subtitles";
import { useRemotionContext } from "../RemotionContext";

const VideoCropOverlay = ({}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoFrameRef = useRef(null);

  const [currentFramePosition, setCurrentFramePosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const {
    videoElement,
    compositionDimensions,
    subtitles,
    scale,
    segments,
    configUpdate,
    currentSegment,
  } = useRemotionContext();

  const onVideoFrame = useCallback(() => {
    if (!canvasRef.current) {
      return;
    }
    const context = canvasRef.current.getContext("2d");

    if (!context) {
      return;
    }

    drawCanvas();
  }, [compositionDimensions, currentSegment]);

  // Synchronize the video with the canvas
  useEffect(() => {
    if (!videoElement?.requestVideoFrameCallback) {
      return;
    }

    let handle = 0;
    const callback = () => {
      onVideoFrame();
      handle = videoElement.requestVideoFrameCallback(callback);
    };

    callback();

    return () => {
      videoElement.cancelVideoFrameCallback(handle);
    };
  }, [onVideoFrame]);

  useEffect(() => {
    if (currentSegment && currentSegment.crop_box) {
      setCurrentFramePosition({
        x: currentSegment?.crop_box.x1 / scale,
        y: currentSegment?.crop_box.y1 / scale,
      });
    }

    drawCanvas();
  }, [currentSegment]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    canvas.width = compositionDimensions.width;
    canvas.height = compositionDimensions.height;

    drawCanvas();
  }, [compositionDimensions, videoElement]);

  const drawCanvas = () => {
    const canvas = canvasRef.current;

    if (!canvas || !currentSegment || !currentSegment.crop_box) return;

    const context = canvas.getContext("2d");

    if (!context) return;

    // Blur effect for the entire canvas
    context.filter = "blur(15px)"; // Adjust the blur strength as needed
    // context.fillRect(0, 0, canvasWidth * getScale(), canvasHeight * getScale());
    context.drawImage(
      videoElement,
      0,
      0,
      compositionDimensions.width,
      compositionDimensions.height
    );

    // Clear the rectangle area (make it transparent)
    context.filter = "none"; // Reset filter for the clear operation

    context.clearRect(
      currentSegment.crop_box.x1,
      currentSegment.crop_box.y1,
      currentSegment.crop_box.x2 - currentSegment.crop_box.x1,
      currentSegment.crop_box.y2 - currentSegment.crop_box.y1
    );
  };

  const handleFrameDrag = (
    e: DraggableEvent,
    position: DraggableData
  ): void => {
    setCurrentFramePosition({
      x: position.x,
      y: position.y,
    });
  };

  const handleFrameDragStop = (
    e: DraggableEvent,
    position: DraggableData
  ): void => {
    const scaledX1: number = position.x * scale;
    const scaledY1: number = position.y * scale;

    const cropbox: ICropBox = {
      x1: +scaledX1.toFixed(2),
      x2: +(
        scaledX1 + getDimensionsFromCropbox(currentSegment?.crop_box).width
      ).toFixed(2),
      y1: +scaledY1.toFixed(2),
      y2: +(
        scaledY1 + getDimensionsFromCropbox(currentSegment?.crop_box).height
      ).toFixed(2),
    };

    configUpdate({
      segments: segments.map((seg) => {
        if (seg.text === currentSegment.text) {
          return {
            ...seg,
            crop_box: cropbox,
          };
        }

        return seg;
      }),
    });
  };

  const getDimensionsFromCropbox = (
    cropbox: ICropBox
  ): { width: number; height: number } => {
    return {
      width: cropbox.x2 - cropbox.x1,
      height: cropbox.y2 - cropbox.y1,
    };
  };

  return (
    <div
      style={{
        position: "relative",
        width: compositionDimensions.width / scale,
        height: compositionDimensions.height / scale,
        margin: "0 auto",
      }}
    >
      <canvas ref={canvasRef} style={{ width: "100%", height: "auto" }} />
      <Draggable
        axis="both"
        handle=".video-frame"
        defaultPosition={{ x: 0, y: 0 }}
        position={currentFramePosition}
        bounds="parent"
        onDrag={handleFrameDrag}
        onStop={handleFrameDragStop}
        scale={scale}
      >
        <div
          ref={videoFrameRef}
          className="video-frame"
          id="videoFrame"
          style={{
            width: `${
              getDimensionsFromCropbox(currentSegment.crop_box).width / scale
            }px`,
            height: `${
              getDimensionsFromCropbox(currentSegment.crop_box).height / scale
            }px`,
          }}
        >
          <div className="corner top-left-h"></div>
          <div className="corner top-left-v"></div>

          <div className="corner top-right-h"></div>
          <div className="corner top-right-v"></div>

          <div className="corner middle-left"></div>
          <div className="corner middle-right"></div>

          <div className="corner bottom-left-h"></div>
          <div className="corner bottom-left-v"></div>

          <div className="corner bottom-right-h"></div>
          <div className="corner bottom-right-v"></div>

          {Array.from(Array(3).keys()).map((t) => (
            <div className="grid-row" key={t}>
              {Array.from(Array(3).keys()).map((i) => (
                <div className="grid-segment" key={i}></div>
              ))}
            </div>
          ))}

          {subtitles && subtitles.items && <Subtitles animationType="none" />}
        </div>
      </Draggable>
    </div>
  );
};

export default VideoCropOverlay;
