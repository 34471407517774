import React, { useImperativeHandle, useRef } from "react";
import { spring, useCurrentFrame, useVideoConfig } from "remotion";
import { ISubtitleWord } from "../types";
import { useRemotionContext } from "../RemotionContext";
import { AnimationType, getAnimationStyles } from "./../helpers";

interface WordCompProps {
  word: ISubtitleWord;
  index: number;
  animationType: AnimationType;
}

const WordComp: React.ForwardRefRenderFunction<
  HTMLSpanElement,
  WordCompProps
> = ({ word, index, animationType }, ref) => {
  const { subtitles } = useRemotionContext();
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  // Calculate exact frame numbers instead of using timeInSeconds
  const wordStartFrame = Math.floor(word.start * fps);
  const wordEndFrame = Math.floor(word.end * fps);
  const isShown = frame >= wordStartFrame && frame <= wordEndFrame;

  const componentRef = useRef<HTMLSpanElement>(null);
  useImperativeHandle(ref, () => componentRef.current as HTMLSpanElement);

  // Smooth interpolation for background opacity
  const backgroundOpacity = spring({
    frame: frame - wordStartFrame,
    fps,
    config: {
      damping: 80,
      mass: 0.4,
      stiffness: 120,
      overshootClamping: true,
    },
  });

  // Animation styles for the highlighted word
  const highlightAnimationStyles = isShown
    ? getAnimationStyles(animationType, {
        frame,
        fps,
        startFrame: wordStartFrame,
        endFrame: wordEndFrame,
      })
    : {};

  // Convert background color to rgba for smooth opacity transition
  const backgroundColor = isShown
    ? subtitles.config.highlightBackgroundColor
    : "transparent";
  const rgbaBackground =
    backgroundColor !== "transparent"
      ? `${backgroundColor}${Math.floor(backgroundOpacity * 255)
          .toString(16)
          .padStart(2, "0")}`
      : "transparent";

  return (
    <span
      ref={componentRef}
      style={{
        borderRadius: "15px",
        backgroundColor: rgbaBackground,
        color: isShown
          ? subtitles.config.highlightColor
          : subtitles.config.color,
        display: "inline-block",
        justifyContent: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontFamily: subtitles.config.font,
        // Remove CSS transitions since we're handling everything with springs
        ...highlightAnimationStyles,
      }}
    >
      {word.word}
    </span>
  );
};

export const WordComponent = React.forwardRef(WordComp);
